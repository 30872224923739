<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Всероссийские соревнования по спортивному программированию среди студентов в Калуге</h4>
              </div>
              <div class="card-body">
                <p><b>С 27 августа по 31 августа пройдут Всероссийские соревнования по спортивному программированию среди студентов в дисциплине «Продуктовое программирование».</b></p>

                <p>Соревнование — это уникальная возможность показать и улучшить свои навыки в разработке программных продуктов. Участники смогут не только познакомиться с единомышленниками, но и создать функциональные решения, способные справляться с реальными проблемами.</p>

                <p>Формат хакатона: очный.</p>

                <p>Место проведения: г. Калуга («KFL Arena», ул. Подвойского, д. 6).</p>

                <p>Участники соревнования: Участниками соревнований могут стать студенты ВУЗов от 18 до 25 лет, являющиеся гражданами Российской Федерации. В составе команды должно быть от 3 до 5 спортсменов, 1 тренер и 1 представитель.</p>

                <p>Задача: Разработка Цифровой Мобильной и Web-Платформы для Автотуристов.</p>

                <p>Кейсодержатель: Ассоциация цифровой трансформации.</p>

                <p>Описание задачи: Создать цифровую платформу, которая станет агрегатором информации и услуг для автотуристов, позволяя легко планировать маршруты, организовывать туры и оплачивать услуги со скидками.</p>

                <p>Образ результат: Приложение демонстрирующее максимальное количество полезного функционала, либо модуль обеспечивающий тот или иной функционал который можно подключить к такому приложению.</p>

                <p>Общий призовой фонд соревнований — 500 000 рублей, с наградами для первых трех победителей:</p>
                <ul>
                  <li>1 место — 250 000 рублей;</li>
                  <li>2 место — 150 000 рублей;</li>
                  <li>3 место — 100 000 рублей;</li>
                </ul>
              </div>
            </div>


            <div class="card card-outline card-success">
              <div class="card-header pt-3">
                <h4>Организаторы</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/minsport.png" class="img-fluid">
                    <p class="mt-3">Министерство спорта Российской Федерации</p>
                  </div>
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/minsport-kaluga.png" class="img-fluid">
                    <p class="mt-3">Министерство спорта Калужской области</p>
                  </div>
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/sport-progr.png" class="img-fluid">
                    <p class="mt-3">Общественная физкультурно – спортивная организация «Федерация спортивного программирования»</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/stoloto.png" class="img-fluid">
                    <p class="mt-3">Акционерное общество «Технологическая Компания «Центр» (Столото)</p>
                  </div>
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/uralhim.png" class="img-fluid">
                    <p class="mt-3">Акционерное общество «Объединенная химическая компания «Уралхим»</p>
                  </div>
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://fsphack.ru/images/act.png" class="img-fluid">
                    <p class="mt-3">Ассоциация цифровой трансформации</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>