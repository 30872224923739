export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'UX/UI',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Оценивается: визуальный дизайн (1-2 балла), современность интерфейса (1-2 балла), удобство использования (1-4 балла), общий пользовательский опыт (1-2 балла).',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 2,
                title: 'Наличие бизнес-логики и бизнес-кейсов',
                sections:
                    [
                        {
                            id: 2,
                            name: 'Оценивается степень проработанности бизнес-логики приложения/модуля (насколько качественно и подробно описана бизнес-логика).',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 3,
                title: 'Информационная наполняемость решения в зависимости от особенностей потребителя',
                sections:
                    [
                        {
                            id: 3,
                            name: 'Оценивается степень проработки решения с точки учета особенностей потребителя.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 4,
                title: 'Информационная наполняемость в зависимости от потребностей потребителя',
                sections:
                    [
                        {
                            id: 4,
                            name: 'Оценивается степень проработки решения с точки зрения степени охвата потребностей автотуриста.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 5,
                title: 'Охват максимально широкого спектра автомобильных (автобусных) и других мультимодальных возможностей с точки зрения специфики (различных профилей) путешественников',
                sections:
                    [
                        {
                            id: 5,
                            name: 'Учитывается степень охвата и проработка (наличия) различных целевых групп автотуристов *.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 6,
                title: 'Охват максимально широкого спектра автомобильных (автобусных) и других мультимодальных возможностей  с точки зрения точек интереса туриста',
                sections:
                    [
                        {
                            id: 6,
                            name: 'Учитывается степень охвата и проработка (наличия) различных точек интереса **.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 7,
                title: 'Общий охват максимально широкого спектра автомобильных (автобусных) и других мультимодальных возможностей',
                sections:
                    [
                        {
                            id: 7,
                            name: 'Оценивается общая степень проработки решения с точки зрения охвата максимально широкого спектра мультимодальных возможностей платформы / модуля, наличие дополнительных услуг и функций.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 8,
                title: 'Демонстрация интеграции с другими сервисами для выдачи «мультимодальной» части функционала',
                sections:
                    [
                        {
                            id: 8,
                            name: 'Оценивается возможность интеграции с другими сервисами, например, с отелями или объектами культурного значения для выполнения ключевого функционала организации (бронирование / покупка билетов). Наличие личного кабинета для взаимодействия с системой провайдеров услуг и сервисов.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 9,
                title: 'Степень вовлечения пользователей',
                sections:
                    [
                        {
                            id: 9,
                            name: 'Оценивается степень проработанности взаимодействия пользователя с контентом интерфейса/приложения.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 10,
                title: 'Креативность',
                sections:
                    [
                        {
                            id: 10,
                            name: 'Оценивается оригинальность и новаторство идеи или подхода к решению задачи.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
        ];
    return criteria;


}