<script setup>
import {onMounted} from "vue";
/*
const today = ref('')
const date = ref('')
const month = ref('')
const year = ref('')

 */
onMounted( ()=>{
  /*
  today.value = new Date();
  date.value = today.value.getDate()
  month.value = today.value.getMonth() + 1
  year.value = today.value.getFullYear()

   */
})
</script>
<template>
  <footer class="main-footer">
    Всероссийские соревнования по спортивному программированию среди студентов в дисциплине «Продуктовое программирование»
    <div class="d-none">
      <a href="https://www.хакатоны.рус/" class="logo-link" target="_blank">
        <img
            src="https://лк.экспохак.рф/images/logohackathonsrus.svg"
            class="logo"
            alt="хакатоны рус - хакатоны, соревнования. Платформа по развитию в IT">
      </a>
    </div>
  </footer>
</template>
<style scoped>
  .logo{
    width:150px;
    height:auto;
  }
</style>